<template>
  <div id="section">
    <b-overlay
      :show="isLoading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="header">
        <b-row>
          <b-col cols="9">
            <label class="subsetor-label">
              Arsenal do centro cirúrgico
            </label>
          </b-col>
          <b-col cols="3">
            <div class="float-right">
              <b-form-radio-group
                class="ml-2"
                :options="radioGroupOptions"
                v-model="tipoDeVisualizacao"
                @input="onRadioGroupInput"
                button-variant="primary-group"
                :disabled="tipoDeVisualizacao === 'pacientes'"
                buttons
              />
              <user-plus-icon size="0.5x" class="ml-3 user-plus" @click="addPaciente()"/>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-input
              v-if="tipoDeVisualizacao === 'pacientes'"
              placeholder="Busca paciente por nome, sala ou médico"
              v-model="inputSearch"
              class="mb-4"
            />
            <MaterialDetection
              v-else
              name="qrCode"
              :isBusy="isLoading"
              hideCamera
              @input="onMaterialSelection"
            />
          </b-col>
          <b-col class="text-right" cols="6">
            <Button
              class="mr-4"
              text="Cancelar"
              variant="grayscale"
              type="text"
              @click="cancel"
            />
            <Button
              text="Salvar leitura"
              :disabled="!materiaisLidos.length"
              @click="save"
            />
          </b-col>
        </b-row>
      </div>

      <div class="content-table">
        <div class="table" v-if="tipoDeVisualizacao === 'pacientes'">
          <div class="mb-4">
            <h4>{{ tipoDeVisualizacao.charAt(0).toUpperCase() + tipoDeVisualizacao.slice(1) }}</h4>
          </div>
          <b-table
            class="material-table"
            hover
            responsive
            :items="filteredItens"
            :fields="tablePacientesFields"
            bordeless
            :busy="false"
            show-empty
            tbody-class="material-table-body"
            thead-class="material-table-head"
            @row-clicked="goToMateriais"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #head(checkbox)>
              <b-form-checkbox></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox v-model="item.checkbox"></b-form-checkbox>
            </template>
            <template #cell(icons)="{ item }">
              <button class="btn float-right" @click="finalizaLiberacao(item)">
                <check-icon size="1.5x"></check-icon>
              </button>
              <button class="btn float-right" @click="cancelaLiberacao(item)">
                <x-icon size="1.5x"></x-icon>
              </button>
              <button class="btn float-right" @click="openModalEditaPaciente(item)">
                <edit-icon size="1.5x" />
              </button>
            </template>
            <template #empty="">
              <div class="empty-table-text">
                Não há nenhum paciente disponível para saídas...
              </div>
            </template>
          </b-table>
        </div>
        <div class="table" v-else>
          <div class="mb-4">
            <h4>{{ currentPaciente.nome_paciente }}</h4>
          </div>
          <b-table
            class="material-table"
            hover
            responsive
            :items="tableMaterialItens"
            :fields="tableMaterialFields"
            bordeless
            :busy="false"
            show-empty
            tbody-class="material-table-body"
            thead-class="material-table-head"
            @row-clicked="goToMateriais"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(remove)="{ item, index }">
              <button class="btn float-right" @click="removeMaterial(item, index)">
                <file-minus-icon size="1.5x"/>
              </button>
            </template>
            <template #empty="">
              <div class="empty-table-text">
                Não há materiais liberados para este paciente...
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-overlay>
    <Modal
      ref="modalAdicionaPaciente"
      title="Cadastro de paciente"
      subTitle="Insira as informações do paciente"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
      :hideXClose="true"
    >
      <form data-vv-scope="main-form" class="form">
        <b-row>
          <b-col>
            <b-form-group label="Paciente">
              <b-form-input
                name="nome_paciente"
                v-model="informacoesNovaSaidaPaciente.nome_paciente"
                placeholder="Informe o nome do paciente"
                :state="validateState('nome_paciente')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Data de nascimento">
              <b-form-datepicker
                locale="pt-BR"
                name="data_nascimento"
                show-decade-nav
                v-model="informacoesNovaSaidaPaciente.data_nascimento"
                placeholder="Selecione uma data"
                :state="validateState('data_nascimento')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Médico">
              <b-form-input
                name="nome_medico"
                v-model="informacoesNovaSaidaPaciente.nome_medico"
                placeholder="Informe o nome do médico"
                :state="validateState('nome_medico')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Sala">
              <b-form-input
                name="sala"
                v-model="informacoesNovaSaidaPaciente.sala"
                placeholder="Informe a sala"
                :state="validateState('sala')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cirurgia">
              <b-form-input
                name="cirurgia"
                v-model="informacoesNovaSaidaPaciente.cirurgia"
                placeholder="Informe a cirurgia"
                :state="validateState('cirurgia')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Data do procedimento">
              <b-form-datepicker
                name="data_procedimento"
                v-model="informacoesNovaSaidaPaciente.data_procedimento"
                placeholder="Selecione uma data"
                locale="pt-BR"
                :state="validateState('data_procedimento')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Hora do procedimento">
              <b-input-group class="mb-3">
                <Hour
                  :isBusy="false"
                  size="sm"
                  v-model="informacoesNovaSaidaPaciente.hora_procedimento"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
      <template #footer>
          <Button
            text="Cancelar"
            variant="grayscale"
            @click="cancelarCadastroPaciente()"
          />
          <Button
              text="Confirmar"
              @click="confirmAddPaciente()"
            />
        </template>
    </Modal>
    <Modal
      ref="modalEditaPaciente"
      title="Edição de cadastro de paciente"
      subTitle="Insira as informações do paciente"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
      :hideXClose="true"
    >
      <form data-vv-scope="edit-form" class="form">
        <b-row>
          <b-col>
            <b-form-group label="Paciente">
              <b-form-input
                name="nome_paciente"
                v-model="novasInformacoesPaciente.nome_paciente"
                placeholder="Informe o nome do paciente"
                :state="validateState('nome_paciente')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Data de nascimento">
              <b-form-datepicker
                locale="pt-BR"
                name="data_nascimento"
                show-decade-nav
                v-model="novasInformacoesPaciente.data_nascimento"
                placeholder="Selecione uma data"
                :state="validateState('data_nascimento')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Médico">
              <b-form-input
                name="nome_medico"
                v-model="novasInformacoesPaciente.nome_medico"
                placeholder="Informe o nome do médico"
                :state="validateState('nome_medico')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Sala">
              <b-form-input
                name="sala"
                v-model="novasInformacoesPaciente.sala"
                placeholder="Informe a sala"
                :state="validateState('sala')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cirurgia">
              <b-form-input
                name="cirurgia"
                v-model="novasInformacoesPaciente.cirurgia"
                placeholder="Informe a cirurgia"
                :state="validateState('cirurgia')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Data do procedimento">
              <b-form-datepicker
                name="data_procedimento"
                v-model="novasInformacoesPaciente.data_procedimento"
                placeholder="Selecione uma data"
                locale="pt-BR"
                :state="validateState('data_procedimento')"
                v-validate="{ required: true }"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Hora do procedimento">
              <b-input-group class="mb-3">
                <Hour
                  :isBusy="false"
                  size="sm"
                  v-model="novasInformacoesPaciente.hora_procedimento"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
      <template #footer>
          <Button
            text="Cancelar"
            variant="grayscale"
            @click="cancelarEdicaoPaciente()"
          />
          <Button
              text="Confirmar"
              @click="confirmarEdicaoPaciente()"
            />
        </template>
    </Modal>

    <Modal
      ref="modalLoginUsuario"
      title="Validação necessária"
      no-close-on-esc
      no-close-on-backdrop
      hideXClose
      hideFooter
      size="lg"
      centered
    >
      <UserAuthentication
        ref="userAuthentication"
        @authenticationResult="onAuthenticationResult"
        @isBusy="(v) => isBusy.authUser = v"
      />
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import GenericApi from '@/services/genericRequest';
import loginService from '@/services/login';

import UserAuthentication from '../../processos/userAuthentication/UserAuthentication';
import MaterialDetection from '../../processos/components/MaterialDetection';

import Button from '@/components/Utils/Button';
import Modal from '@/components/Utils/Modal';
import Hour from '@/components/Utils/Hour';

export default {
  name: 'CentroCirurgico',
  components: {
    UserAuthentication,
    MaterialDetection,
    Button,
    Modal,
    Hour,
  },

  provide() {
    return {
      validator: this.$validator,
      validateState: this.validateState,
    };
  },

  watch: {
    inputSearch: {
      handler(value) {
        if (value) {
          const itens = this.tablePacientesItens
            .filter((item) => item.nome_paciente === value || item.nome_medico === value || item.sala === value);

          if (itens.length) {
            this.filteredItens = itens;
          } else {
            this.filteredItens = this.tablePacientesItens;
          }
        } else {
          this.filteredItens = this.tablePacientesItens;
        }
      },
      immediate: true,
    },

    subSetor() {
      this.reset();
    },

    usuarioLogado(usuario) {
      this.$store.commit('setusuarioLogadoProcesso', usuario);
      if (usuario.id_usuario) {
        this.$refs.modalLoginUsuario.hide();
      } else {
        this.$refs.modalLoginUsuario.show();
      }
    },
  },

  created() {
    this.fillAllSaidasAbertas();
  },

  mounted() {
    this.handleLoginUsuario(true);
  },

  data() {
    return {
      // TODO: verificar todos os dados e formas que são preenchidos
      tablePacientesItens: [],
      tablePacientesFields: [{
        key: 'nome_paciente',
        label: 'Nome do paciente',
      }, {
        key: 'sala',
        label: 'Sala',
      }, {
        key: 'nome_medico',
        label: 'Nome do médico',
      }, {
        key: 'icons',
        label: '',
      }],
      tableMaterialItens: [],
      tableMaterialFields: [{
        key: 'descricao',
        label: 'Descrição',
      }, {
        key: 'id',
        label: 'ID',
      }, {
        key: 'quantidade_itens',
        label: 'Quantidade de itens',
      }, {
        key: 'quantidade',
        label: 'Quantidade',
      }, {
        key: 'remove',
        label: '',
      }],

      currentPaciente: {},

      filteredItens: [],
      inputSearch: '',
      tipoDeVisualizacao: 'pacientes',
      reportSizes: {
        etiqueta: 'md',
        relatorio: 'lg',
        default: 'lg',
      },
      isBusy: {
        authUser: false,
        protocolo: false,
        materialReading: false,
        sendingForm: false,
      },
      informacoesNovaSaidaPaciente: {},
      novasInformacoesPaciente: {},

      radioGroupOptions: [{
        text: 'Pacientes',
        value: 'pacientes',
      }, {
        text: 'Materiais',
        value: 'materiais',
      }],

      model: {},

      usuarioLogado: {},
      materiaisLidos: [],
      processoFormulario: {},
      respostasProcessoFormulario: {},

      respostasMaterialFormulario: {},
      materialSelecionado: {},
      chaveFormularioMaterialSelecionado: null,
      promiseFormularioMaterialObrigatorio: null,

      promiseQuantidadeGenerico: null,
      quantidadeGenerico: null,

      promiseNovoProtocolo: null,
      novosProtocolos: [],
      mensagemProtocolo: '',

      lastRelatorioProceso: {},
    };
  },

  computed: {
    ...mapState([
      'currentUnidade',
      'configuracao',
      'subSetores',
      'usuarioLogadoProcesso',
    ]),

    isLoading() {
      return Boolean(Object.keys(this.isBusy).find((k) => this.isBusy[k]));
    },

    subSetor() {
      if (this.subSetores.length && this.$route.params.chave) {
        return this.subSetores.find((subSet) => subSet.chave === this.$route.params.chave);
      }

      return null;
    },

    formularioMaterialSelecionado() {
      if (!this.materialSelecionado || !this.materialSelecionado.formularios
        || !this.chaveFormularioMaterialSelecionado) return {};

      return this.materialSelecionado.formularios.find((f) => f.title === this.chaveFormularioMaterialSelecionado);
    },
  },

  methods: {
    addPaciente() {
      this.$refs.modalAdicionaPaciente.show();
    },

    async cancelarEdicaoPaciente() {
      const selection = await swal({
        title: 'ATENÇÃO',
        text: 'Você está prestes a cancelar a edição deste paciente. Deseja continuar?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (selection) {
        this.$refs.modalEditaPaciente.hide();
        this.novasInformacoesPaciente = {};
      }
    },

    async confirmarEdicaoPaciente() {
      this.$validator.resume();
      const validationResult = await this.$validator.validateAll('edit-form');

      if (!validationResult) return;

      try {
        await GenericApi.update(
          this.novasInformacoesPaciente.id_saida_paciente,
          this.novasInformacoesPaciente,
          'saidaPaciente',
        );
        await swal({
          title: 'Bom trabalho!',
          text: 'O paciente foi editado com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.novasInformacoesPaciente = {};
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao editar um paciente',
          icon: 'error',
          button: 'Continuar',
        });
      }

      this.$refs.modalEditaPaciente.hide();
    },

    openModalEditaPaciente(item) {
      [this.novasInformacoesPaciente] = this.tablePacientesItens
        .filter((paciente) => paciente.id_saida_paciente === item.id_saida_paciente);
      this.$refs.modalEditaPaciente.show();
    },

    async confirmAddPaciente() {
      this.$validator.resume();
      const validationResult = await this.$validator.validateAll('main-form');

      if (!validationResult) return;

      try {
        const result = await GenericApi.create(
          this.informacoesNovaSaidaPaciente,
          'saidaPaciente',
        );
        await swal({
          title: 'Bom trabalho!',
          text: 'O paciente foi adicionado com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.tablePacientesItens.push({
          id_saida_paciente: result.id_saida_paciente,
          data_nascimento: result.data_nascimento,
          cirurgia: result.cirurgia,
          hora_procedimento: result.hora_procedimento,
          data_procedimento: result.data_procedimento,
          finalizado: false,
          cancelado: false,
          nome_paciente: result.nome_paciente,
          sala: result.sala,
          nome_medico: result.nome_medico,
        });

        this.filteredItens = this.tablePacientesItens;
        this.informacoesNovaSaidaPaciente = {};
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao criar uma nova saída',
          icon: 'error',
          button: 'Continuar',
        });
      }

      this.$refs.modalAdicionaPaciente.hide();
    },

    async cancelarCadastroPaciente() {
      const selection = await swal({
        title: 'ATENÇÃO',
        text: 'Você está prestes a cancelar a adição deste paciente. Deseja continuar?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (selection) {
        this.$refs.modalAdicionaPaciente.hide();
        this.informacoesNovaSaidaPaciente = {};
      }
    },

    async cancelaLiberacao(item) {
      const selection = await swal({
        title: 'ATENÇÃO',
        text: 'Você está prestes a cancelar as saídas deste paciente. Deseja continuar?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (selection) {
        await GenericApi.update(
          item.id_saida_paciente,
          {
            ...item,
            finalizado: false,
            cancelado: true,
          },
          'saidaPaciente',
        );

        const indexInMainArray = this.tablePacientesItens
          .map((paciente) => paciente.nome_paciente)
          .indexOf(item.nome_paciente);

        this.tablePacientesItens.splice(indexInMainArray, 1);
        this.filteredItens = this.tablePacientesItens;
      }
    },

    async finalizaLiberacao(item) {
      const selection = await swal({
        title: 'ATENÇÃO',
        text: 'Você está prestes a finalizar as saídas deste paciente. Deseja continuar?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (selection) {
        await GenericApi.update(
          item.id_saida_paciente,
          {
            ...item,
            finalizado: true,
            cancelado: false,
          },
          'saidaPaciente',
        );

        const indexInMainArray = this.tablePacientesItens
          .map((paciente) => paciente.nome_paciente)
          .indexOf(item.nome_paciente);

        this.tablePacientesItens.splice(indexInMainArray, 1);
        this.filteredItens = this.tablePacientesItens;
      }
    },

    reset() {
      this.tableMaterialItens = this.tableMaterialItens
        .filter((material) => {
          if (material.id_material) {
            return !this.materiaisLidos
              .map((materialLido) => materialLido.id_material)
              .includes(material.id_material);
          }
          return !this.materiaisLidos
            .map((materialLido) => materialLido.id_material_generico)
            .includes(material.id_material_generico);
        });
      this.materiaisLidos = [];

      this.tipoDeVisualizacao = 'pacientes';
    },

    async fillAllSaidasAbertas() {
      const saidasAbertas = await GenericApi.getWithoutPagination(
        {},
        'saidaPaciente/allPacientesComSaidasAbertas',
      );

      this.tablePacientesItens = saidasAbertas;
      this.filteredItens = saidasAbertas;
    },

    async goToMateriais(item) {
      [this.currentPaciente] = this.tablePacientesItens
        .filter((paciente) => paciente.nome_paciente === item.nome_paciente);

      this.tipoDeVisualizacao = 'materiais';

      let materiaisFromPaciente = await GenericApi.getWithoutPagination(
        {},
        `saidaPaciente/getMateriaisFromPaciente/${item.id_saida_paciente}`,
      );

      materiaisFromPaciente = materiaisFromPaciente.reduce((acumulado, material) => {
        if (material.id_material) {
          acumulado.push(material);
          return acumulado;
        }

        const idxMaterialGenerico = acumulado
          .findIndex((m) => m.id_material_generico === material.id_material_generico);

        if (idxMaterialGenerico !== -1) {
          acumulado[idxMaterialGenerico].quantidade++;
          return acumulado;
        }

        acumulado.push({ ...material, quantidade: 1 });
        return acumulado;
      }, []);

      this.tableMaterialItens = materiaisFromPaciente;
    },

    onRadioGroupInput(checked) {
      if (this.materiaisLidos.length && checked === 'pacientes') {
        swal({
          title: 'ATENÇÃO',
          text: 'Ao sair dessa tela todos os materias lidos serão perdidos. Deseja continuar?',
          icon: 'warning',
          buttons: { cancel: 'Não', confirm: 'Sim' },
        }).then((value) => {
          if (value) {
            this.tipoDeVisualizacao = checked;
            this.materiaisLidos = [];
          } else this.tipoDeVisualizacao = 'materiais';
        });
      } else {
        this.tipoDeVisualizacao = checked;
      }
    },

    handleLoginUsuario(justMounted = false) {
      if (!this.$refs.modalLoginUsuario) {
        setTimeout(this.handleLoginUsuario.bind(this), 200, justMounted);
        return;
      }

      if (this.$route.query && this.$route.query.justLoggedIn) {
        this.usuarioLogado = loginService.getUser();
      } else if (this.usuarioLogadoProcesso.id_usuario && !justMounted) {
        this.usuarioLogado = this.usuarioLogadoProcesso;
      }

      if (this.usuarioLogado.id_usuario) {
        this.$refs.modalLoginUsuario.hide();
      } else {
        this.$refs.modalLoginUsuario.show();
      }
    },

    async cancel() {
      const cancelConfirmation = await swal({
        title: 'Cancelamento de formulário',
        text: 'Deseja cancelar a liberação destes materiais?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (!cancelConfirmation) return;

      this.reset();
    },

    onAuthenticationResult(user) {
      this.usuarioLogado = user;
    },

    cancelaFormularioMaterial() {
      this.$refs.modalFormularioMaterial.hide();
      if (this.promiseFormularioMaterialObrigatorio) this.promiseFormularioMaterialObrigatorio(false);
    },

    async validateFormularioMaterial() {
      if (this.currentPage === 0) {
        return this.authenticateUser();
      }

      this.$validator.resume();
      const result = await this.$validator.validateAll('formulario-material');

      return result;
    },

    async confirmaFormularioMaterial() {
      if (!(await this.validateFormularioMaterial())) return;

      this.materialSelecionado
        .respostasFormularios[this.chaveFormularioMaterialSelecionado] = this.respostasMaterialFormulario;

      if (this.promiseFormularioMaterialObrigatorio) this.promiseFormularioMaterialObrigatorio(true);
      this.$refs.modalFormularioMaterial.hide();
    },

    confirmaQuantidadeGenerico() {
      if (!this.quantidadeGenerico || !parseInt(this.quantidadeGenerico, 10)) return;

      if (this.promiseQuantidadeGenerico) this.promiseQuantidadeGenerico(+this.quantidadeGenerico);
      this.$refs.modalQuantidadeGenerico.hide();
    },

    cancelaQuantidadeGenerico() {
      this.$refs.modalQuantidadeGenerico.hide();
      if (this.promiseQuantidadeGenerico) this.promiseQuantidadeGenerico(false);
    },

    async abreModalQuantidade() {
      this.quantidadeGenerico = null;
      this.$refs.modalQuantidadeGenerico.show();
    },

    solicitaQuantidadeMaterialGenerico() {
      this.abreModalQuantidade();
      const promise = new Promise((resolve) => {
        this.promiseQuantidadeGenerico = resolve;
      });

      promise.then(() => { this.promiseQuantidadeGenerico = null; });
      return promise;
    },

    confirmaSelecaoProcesso() {
      if (this.promiseSelecaoProcesso) this.promiseSelecaoProcesso(this.tipoProcessoSelecionado);
      this.$refs.modalSelecaoProcesso.hide();
    },

    async removeMaterial(material) {
      const selection = await swal({
        title: 'ATENÇÃO',
        text: 'Você está prestes a remover este material. Deseja continuar?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (selection) {
        let indexMaterial;
        do {
          indexMaterial = this.tableMaterialItens
            .findIndex((m) => (m.id_material && m.id_material === material.id_material)
              || (m.id_material_generico && m.id_material_generico === material.id_material_generico));

          if (indexMaterial !== -1) {
            // eslint-disable-next-line no-await-in-loop
            await GenericApi.delete(
              this.tableMaterialItens[indexMaterial].id_material
                ? this.tableMaterialItens[indexMaterial].id_material
                : this.tableMaterialItens[indexMaterial].id_material_generico,
              'saidaPaciente/removeMaterial', {
                id_saida_paciente: this.currentPaciente.id_saida_paciente,
              }, {
                deletedAt: moment(),
              },
            );
            this.tableMaterialItens.splice(indexMaterial, 1);
          } else {
            indexMaterial = this.materiaisLidos
              .findIndex((m) => (m.id_material && m.id_material === material.id_material)
              || (m.id_material_generico && m.id_material_generico === material.id_material_generico));

            this.materiaisLidos.splice(indexMaterial, 1);
          }
        } while (indexMaterial !== -1);
      }
    },

    adicionaMaterial(resultadoLeitura, quantidadeAdicionada) {
      this.processoFormulario = resultadoLeitura.formularioProcesso;

      const novoMaterial = {
        id_material: resultadoLeitura.materialLido.id_material,
        id_material_generico: resultadoLeitura.materialLido.id_material_generico,
        id_material_protocolo: resultadoLeitura.materialLido.id_material_protocolo,
        ids_material_protocolo: resultadoLeitura.materialLido.ids_material_protocolo,
        shownId: resultadoLeitura.materialLido.shownId || '-',
        quantidade_adicionada: quantidadeAdicionada || 1,
        quantidade_atual: resultadoLeitura.materialLido.quantidade_atual_itens || quantidadeAdicionada,
        quantidade_pecas: resultadoLeitura.materialLido.quantidade_itens || resultadoLeitura.materialLido.pecas,
        descricao: resultadoLeitura.materialLido.descricao,
        formularios: resultadoLeitura.formularioMaterial,
        processoTipoMaterial: resultadoLeitura.processoTipoMaterial,
        respostasFormularios: {},
      };

      this.materiaisLidos.push(novoMaterial);
      return novoMaterial;
    },

    async onMaterialSelection({ value, type, quantidade }, solicitaProtocolo = false) {
      let novoMaterial;
      let quantidadeAdicionada = quantidade;

      if (!this.usuarioLogado.id_usuario) {
        this.handleLoginUsuario();
        return;
      }

      try {
        this.isBusy = true;

        if (type === 'code' && value.slice(0, 6) === 'INVMGE') {
          quantidadeAdicionada = await this.solicitaQuantidadeMaterialGenerico();
          if (!quantidadeAdicionada) {
            swal({
              icon: 'error',
              title: 'Erro',
              text: 'Leitura do material cancelada',
              buttons: { confirm: 'Fechar' },
            });

            return;
          }
        }

        let novosProtocolos;
        const protocolos = this.protocolos
          ? this.protocolos.map((p) => p.codigo) : [];

        if (!solicitaProtocolo && ((type === 'code' && value.slice(0, 6) === 'INVMGE') || type === 'generico')) {
          this.mensagemProtocolo = '';

          novosProtocolos = this.handleProtocoloGenerico
            ? await this.handleProtocoloGenerico({ value, type }) : null;
          if (novosProtocolos) {
            // eslint-disable-next-line no-restricted-syntax
            for (const p of novosProtocolos) {
              protocolos.push(p);
              // eslint-disable-next-line no-await-in-loop
              await this.adicionaNovoProtocolo(p);
            }
          }
        }

        if (solicitaProtocolo) {
          novosProtocolos = await this.solicitaNovoProtocolo();

          // * Existiam situações em que o código não aguardava a resolução da promise
          // * Não consegui entender o motivo para arrumar
          // * O código abaixo, espera de 100ms, faz o problema não ocorrer
          await new Promise((r) => { setTimeout(r, 100); });

          if (!novosProtocolos) throw 'Leitura cancelada pois não foi inserido protocolo';

          // eslint-disable-next-line no-restricted-syntax
          for (const p of novosProtocolos) {
            protocolos.push(p);
            // eslint-disable-next-line no-await-in-loop
            await this.adicionaNovoProtocolo(p);
          }
        }

        const route = `formularios/leitura/${type}/${value}`;
        const data = {
          tipoProcesso: 'saidaPaciente',
          subSetor: 'arsenalCentroCirurgico',
          idUnidade: this.currentUnidade && this.currentUnidade.id_unidade,
          quantidade: quantidadeAdicionada,
          protocolos: JSON.stringify(protocolos),
          materiaisLidos: JSON.stringify(this.materiaisLidos),
          processoFormularioAtual: JSON.stringify(this.processoFormulario),
        };
        const resultadoLeitura = await GenericApi.create(data, route);

        const materialJaExiste = this.materiaisLidos
          .find((m) => (m.id_material && m.id_material === resultadoLeitura.materialLido.id_material)
          || (m.id_material_generico && m.id_material_generico === resultadoLeitura.materialLido.id_material_generico))
          || this.tableMaterialItens
            .find((m) => (m.id_material && m.id_material === resultadoLeitura.materialLido.id_material)
          || (m.id_material_generico && m.id_material_generico === resultadoLeitura.materialLido.id_material_generico));

        if (materialJaExiste) {
          swal({
            icon: 'error',
            title: 'Erro',
            text: 'O material informado já foi lido',
            buttons: { confirm: 'Fechar' },
          });

          return;
        }

        if (resultadoLeitura.precisaNovoProtocolo) {
          if (resultadoLeitura.novoProtocolo) {
            const protocolo = `${resultadoLeitura.novoProtocolo.sigla}${resultadoLeitura.novoProtocolo.numero}`;
            await this.adicionaNovoProtocolo(protocolo);
          } else {
            if (((type === 'code' && value.slice(0, 6) === 'INVMGE') || type === 'generico')) {
              this.mensagemProtocolo = 'Os protocolos adicionados não permitem a passagem de todos os materiais';
            } else {
              this.mensagemProtocolo = 'Não foi possível obter o protocolo automaticamente, favor informar manualmente';
            }

            this.onMaterialSelection({ value, type, quantidade }, true);
            return;
          }
        }

        novoMaterial = this.adicionaMaterial(resultadoLeitura, quantidadeAdicionada);
        this.tableMaterialItens.push({
          descricao: novoMaterial.descricao,
          id: novoMaterial.shownId,
          id_material: novoMaterial.id_material,
          id_material_generico: novoMaterial.id_material_generico,
          quantidade_itens: novoMaterial.quantidade_pecas,
          quantidade: novoMaterial.quantidade_atual,
        });
      } catch (error) {
        console.log(error);
        let errorMessage = 'Ocorreu um erro ao recuperar o material';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        if (typeof error === 'string') errorMessage = error;

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy = false;
      }
    },

    async save() {
      try {
        this.isBusy = true;

        const protocolos = JSON
          .stringify(this.protocolos ? this.protocolos.map((p) => p.codigo) : []);
        const formularioProcesso = JSON.stringify(this.currentPaciente);
        const materiais = JSON.stringify(this.materiaisLidos);
        const tipoProcesso = 'saidaPaciente';
        const subSetor = 'arsenalCentroCirurgico';
        const idUnidade = this.currentUnidade && this.currentUnidade.id_unidade;
        const idUsuario = this.usuarioLogado.id_usuario;

        await GenericApi.create({
          protocolos,
          materiais,
          formularioProcesso,
          tipoProcesso,
          subSetor,
          idUnidade,
          idUsuario,
        }, 'formularios/registroProcesso');

        swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Os materiais foram registrados com sucesso',
          buttons: { confirm: 'Fechar' },
        });

        this.reset();
      } catch (error) {
        console.log(error);
        let errorMessage = 'Ocorreu um erro ao registrar o processo';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy = false;
      }
    },

    validateState(ref) {
      const allVeeFields = Object.keys(this.veeFields)
        .reduce((fields, scope) => ({ ...fields, ...this.veeFields[scope] }), {});

      if (
        allVeeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (allVeeFields[ref].dirty || allVeeFields[ref].validated)
      ) {
        return !this.veeErrors.has(`main-form.${ref}`) && !this.veeErrors.has(`formulario-material.${ref}`);
      }
      return null;
    },
  },
};
</script>

<style lang='scss' scoped>
$neutral-color-80: #35384d;
  // HEADER
  .header {
    margin-top: -36px;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #FFFFFF;
    border-bottom: 1px solid #CFD1E6;
  }

  .subsetor-label {
    font-weight: 500;
    font-size: 2rem;
    padding-bottom: 1rem;
    color: #35384D !important;
  }

  // EMPTY
  .empty-div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.4s;
  }
  .empty-div.hidden {
    opacity: 0;
  }

  .empty-label {
    color: #5E627A;
  }

  // MAIN
  .main {
    padding-top: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  // FORM
  .empty-form {
    font-size: 1rem;
    color: #7E829F !important;
    margin-top: 15px;
  }
</style>

<style>
.subsetor-dropdown .button {
  background-color: white !important;
  margin-left: 10px;
  border: 2px solid #e3e1ed !important;
  border-radius: 7px !important;
  color: black;
  height: 2rem;
  width: 2rem;
}

.subsetor-dropdown .chevron {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-plus, .x-icon, .check-icon {
  cursor: pointer;
}

.table-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.content-table {
  margin-top: 5%;
  width: 80%;
  margin-left: 10%;
  padding-top: 2.5rem;
  padding-left: 2rem;
  padding-right: 4rem;
  border-radius: 15px;
}

.table {
  padding: 6px;
  margin-bottom: 50px;
  border-color: #C2C9D1;
  border-radius: 10px;
}

.table-hover tbody thead tr:hover {
  background-color: #f2f2f2;
}

.row-class, .head-class, .content-table {
  background-color: white;
}

.icons-controle-liberacao {
  position: fixed;
  left: 10%;
}

.empty-table-text {
  position: relative;
  left: 27%;
}

.check-icon, .x-icon {
  color: #35384d;
  width: 15px
}

.x-icon {
  margin-left: 250px;
}

.confirm-add-paciente-button {
  position: relative;
  left: 30%;
  margin-top: 25px;
}

.confirm-remove-paciente-button {
  position: fixed;
  left: 59%;
  margin-top: 25px;
}

.material-table {
  border-radius: 8px;
  border: 1px solid #dee2e6 !important;
}

.material-table .table thead th {
  border: none;
}
.material-table .material-table-body {
  color: #35384D;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
}

.material-table .material-table-head {
  color: #5E627A;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
}

.material-table .material-table-head .icon-list {
  position: absolute;
  left: 2rem;
}
</style>
